import React, { useState } from 'react';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { useNavigate } from 'react-router-dom';
import SiteLogo from './SiteLogo';

export default function HomeView() {
  useDocumentTitle();

  return (
    <div className="c-home-view u-margin-top-large">
      <SiteLogo size="large" />
      <RSSForm />
    </div>
  );
}

var timer = null;

function RSSForm() {
  let navigate = useNavigate();
  const [rssFeed, setRssFeed] = useState("");
  const [rssItems, setRssItems] = useState("");

  function isValidUrl(string) {
    try {
      new URL(string);
    } catch (e) {
      return false;
    }

    return true;
  }

  function handleSubmit(event) {
    if (isValidUrl(rssFeed)) {
      navigate('/podcast?rss=' + encodeURIComponent(rssFeed));
    }
    event.preventDefault();
  }

  function handleImFeelingLucky(event) {
    const obsessedWithPodcast = "https://podcasts.files.bbci.co.uk/p0742833.rss"
    navigate('/podcast?rss=' + encodeURIComponent(obsessedWithPodcast));
    event.preventDefault();
  }

  function getPodcastRss(keyword) {
    let url = 'https://pod.link/search?query=' + keyword;
    fetch(url)
    .then((response) => response.json())
    .then((data) => {
      if (data) {
        setRssItems(data);
      }
    })
    .catch(function (error) {
        console.log(error);
    });

    /*
    axios.get(url)
    .then(function (response) {
        if (response && response.data) {
          setRssItems(response.data);
        }
    })
    .catch(function (error) {
        console.log(error);
    });
    */
  }

  function handleChange(event) {
    let url = event.target.value;
    setRssFeed(url);
    if (!isValidUrl(url)) {
      timer && clearTimeout(timer);
      timer = setTimeout(function(keyword) {
        getPodcastRss(keyword);
      }, 300, url);
    }
  }

  function setRssUrl(url) {
    navigate('/podcast?rss=' + encodeURIComponent(url));
  }

  const disabled = !isValidUrl(rssFeed);

  return (
    <form onSubmit={handleSubmit} className="c-rss-form u-margin-top-large">
      <div className="c-rss-form__input-wrap u-margin-bottom-large">
        <input
          type="url"
          value={rssFeed}
          onChange={handleChange}
          placeholder="Enter a podcast RSS feed…"
          className="c-rss-form__input"
        />
      </div>
      <div className="c-rss-form__actions">
        <button type="submit" className="c-rss-form__button" onClick={handleSubmit} disabled={disabled}>
          Play Podcast
        </button>
        <button type="button" className="c-rss-form__button" onClick={handleImFeelingLucky}>
          I’m Feeling Lucky
        </button>
      </div>
      <div className="c-rss-url-listbox">
        {rssItems.length > 0 && rssItems.map( item  => <div key={item.id} onClick={setRssUrl.bind(this, item.href)} className="c-rss-a"><img src={item.image} alt="" className="c-rss-image" /><div><div>{item.title}</div><div>{item.ownerName}</div></div></div> )}
      </div>
    </form>
  );
}
